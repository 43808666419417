import React from "react";
// Customizable Area Start
// import styled from "styled-components";
import { menubarIcon, katorlogo, fullsolidcircle, fullwaveimage, halfwaveimage, arrowForwardIcon, footerkatorlogo, footerArrForwIcon, dotGridImage, verticalHalfCircle, dotGrid5x6Image } from "./assets";

// const MainContainer = styled.div`
//   @media (min-width: 576px) {
//     display: flex;
//     margin: auto;
//     width: 390px;
//   }

//   @media (max-width: 575px) {
//     width: 100%;
//   }
// `;

// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export default class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <div style={styles.mainContainer}>
        <div style={styles.container}>
          <nav style={styles.nav}>
            <img src={menubarIcon} alt="menuIcon" />
          </nav>
          <div style={styles.logoContainer}>
            <img style={styles.katorlogo} src={katorlogo} alt="kator" />
          </div>
          <div style={styles.firstdesignpattern}>
            <img style={styles.dotgrid6X6} src={dotGridImage} alt="dotgrid6x6" />
            <img style={styles.fullcircle} src={fullsolidcircle} alt="fullcircle" />
            <img style={styles.halfwaveImage} src={halfwaveimage} alt="halfwaveimage" />
          </div>
          <div style={styles.titleContainer}>
            <span style={styles.title}>{configJSON.landingPageTitle}</span>
            <span style={styles.subtitle}>{configJSON.landingPageSubtitle}</span>
          </div>

          <div style={styles.secondbagImageContainer}>
            <img style={styles.dotgrid5X6} src={dotGrid5x6Image} alt="dotgrid" />
            <div style={styles.btnContainer}>
              <button style={styles.textBtn}>
                {configJSON.Begin}
                <img style={styles.forwArrIcon} src={arrowForwardIcon} alt="rightArrow" />
              </button>
            </div>
          </div>
          <div style={styles.halfcirclecontainer}>
              <img style={styles.verticalHalfCircle} src={verticalHalfCircle} alt="halfcircle" />
            </div>
          <div style={styles.titleContainer}>
            <span style={styles.title}>{configJSON.aboutus}</span>
            <span style={styles.subtitle}>{configJSON.aboutusDescription}</span>
          </div>
          <div style={{ ...styles.btnContainer, margin: "20px 16px" }}>
            <button style={styles.textBtn}>
              {configJSON.Start}
              <img style={styles.forwArrIcon} src={arrowForwardIcon} alt="rightArrow" />
            </button>
          </div>
          <div style={styles.contactFormWrapper}>
            <img style={styles.fullwaveImageBehindForm} src={fullwaveimage} alt="fullwaveimage" />
            <div style={styles.contactFormContainer}>
              <div style={styles.formTitleContainer}>
                <span style={styles.formtitle}>{configJSON.contactus}</span>
                <span style={styles.formSubtitle}>{configJSON.contactusDescription}</span>
              </div>
              <div style={styles.formContainer}>
                <div style={styles.groupInputWithLabel}>
                  <label style={styles.textlabel}>{configJSON.fullName}</label>
                  <input name='fullname' style={styles.textinput} placeholder='John Smith' value={this.state.fullname} onChange={this.handleInputChange}/>
                </div>
                <div style={styles.groupInputWithLabel}>
                  <label style={styles.textlabel}>{configJSON.Email}</label>
                  <input name='email' style={styles.textinput} placeholder='name@email.com' value={this.state.email} onChange={this.handleInputChange}/>
                </div>
                <div style={styles.groupInputWithLabel}>
                  <label style={styles.textlabel}>{configJSON.contactusTextareaLabel}</label>
                  <textarea name='description' style={styles.textareainput} placeholder='You can write your questions here' value={this.state.description} onChange={this.handleInputChange}/>
                  <span style={styles.descriptionTextLength}>{`${this.state.description.length}/50`}</span>
                </div>
                <div style={{ ...styles.btnContainer, margin: "0px" }}>
                  <button style={{ ...styles.textBtn, color: "#F1ECE4" }}>
                    {configJSON.Send}
                    <img style={styles.forwArrIcon} src={footerArrForwIcon} alt="rightArrow" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div style={styles.footerContainer}>
            <div style={styles.footerContent}>
              <div style={styles.lefttext}>
                <span style={styles.leftfooterText}>
                  <img style={styles.footerkatorlogo} src={footerkatorlogo} alt="kator" />
                </span>
                <span style={styles.leftfooterText}>{configJSON.privacypolicytext}</span>
              </div>
              <div style={styles.righttext}>
                <span style={styles.rightfooterText}>{configJSON.katortext}</span>
                <span style={styles.rightfooterText}>{configJSON.termsconditionstext}</span>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

// Customizable Area Start

const styles = {
  mainContainer: {
    display: "flex",
    margin: "auto",
    width: "390px",
    "@media (max-width: 575px)": {
      width: "100%",
    },
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#F1ECE4",
  },
  nav: {
    backgroundColor: "transparent",
    height: "48px",
    display: "flex",
    alignItems: "center",
    margin: "0px 0px 0px 8px"
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    top: "114px",
    marginBottom: "20px",
  },
  katorlogo: {
    width: "108px",
    height: "33px",
  },
  firstdesignpattern: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "12.5rem",
    margin: "2rem 0rem",
    position: "relative" as const,
  },
  dotgrid6X6: {
    position: "absolute" as const,
    width: "6.25rem",
    height: "6.25rem",
    left: "3rem",
    top: "6.5rem",
    zIndex: "1",
  },
  fullcircle: {
    position: "absolute" as const,
    left: "4rem",
    width: "10.25rem",
    height: "10.25rem",
  },
  halfwaveImage: {
    position: "absolute" as const,
    right: "0rem",
    top: "2rem",
    width: "15rem",
    height: "10rem",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
    justifyContent: "center",
    alignItems: "start",
    padding: "8px 24px",
    top: "426px",
  },
  title: {
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left" as "left",
    margin: "0px",
  },
  subtitle: {
    color: "#706968",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
    margin: "0px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "0px 16px"
  },
  secondbagImageContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  dotgrid5X6: {
    width: "6.25rem",
    height: "6.25rem",
  },
  halfcirclecontainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  verticalHalfCircle: {

  },
  textBtn: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  forwArrIcon: {
    width: "24px",
    height: "24px",
  },
  contactFormWrapper: {
    position: "relative" as const,
    width: "100%",
    height: "auto",
    marginTop: "5rem"
  },
  fullwaveImageBehindForm: {
    position: "absolute" as const,
    width: "50%",
    height: "auto",
    top: "-3rem",
    left: "0",
  },
  contactFormContainer: {
    backgroundColor: "#706968",
    borderRadius: "40px 40px 0px 0px",
    padding: "50px 0px",
    position: "relative" as const,
    zIndex: "1",
  },
  formTitleContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "start",
    gap: "8px",
    padding: "8px 24px",
  },
  formtitle: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left" as "left",
  },
  formSubtitle: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
    margin: "0px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "24px",
    padding: "24px 24px 0px",
  },
  groupInputWithLabel: {
    display: "flex",
    flex: "1",
    minWidth: "100%",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "4px",
  },
  textlabel: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    textAlign: "left" as "left",
    // margin: "0px",
  },
  textinput: {
    width: "100%",
    height: "44px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #F1ECE4",
    outline: "none",
    opacity: "0px",
    backgroundColor: "transparent",
    color: "#F1ECE4B2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  textareainput: {
    width: "100%",
    height: "100px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #F1ECE4",
    outline: "none",
    opacity: "0px",
    backgroundColor: "transparent",
    color: "#F1ECE4B2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  descriptionTextLength: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "right" as "right",
  },
  footerContainer: {
    backgroundColor: "#524948",
    padding: "16px",
  },
  footerContent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  lefttext: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
  },
  righttext: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
  },
  footerkatorlogo: {
    width: "auto",
    height: "20px",
  },
  leftfooterText: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left" as "left",
  },
  rightfooterText: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "right" as "right",
  }
};
// Customizable Area End
